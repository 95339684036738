import { Trans, useTranslation } from 'react-i18next'
import AdminNavBar from '../components/NavBar/AdminNavBar'
import PageContent from '../components/PageContent'
import { ReactNode } from 'react'
import Link from 'next/link'

const LinkText = ({ href, children }: { href: string; children: ReactNode }) => {
  return (
    <Link href={href || ''} legacyBehavior>
      <a className='underline'>{children}</a>
    </Link>
  )
}

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <>
      <AdminNavBar />
      <PageContent className='mx-4'>
        <div className='flex h-full flex-col items-center justify-center'>
          <h1 className='pt-6 pb-2'>{t('pageNotFound')}</h1>
          <h4>
            <Trans i18nKey='pleaseClickHereToGoHome'>
              Ole hyvä ja <LinkText href='/'>napsauta tästä</LinkText> palataksesi etusivulle.
            </Trans>
          </h4>
        </div>
      </PageContent>
    </>
  )
}

export default NotFound
